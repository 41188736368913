<template>
  <div id="app">
    <div class="video-background">
      <video autoplay loop muted>
        <source src="bg.mp4" type="video/mp4">
        您的浏览器不支持视频标签。
      </video>
    </div>
    <div class="title-image">
      <img src="title.png" alt="Title Image" />
    </div>
    <div class="content">
      <button class="button" onclick="window.location.href='https://pump.fun/t5kcK2JaTN2J89N3LT1MXK2Ye7LRPdR3nyC6qdmpump'"><span class="buy-text">BUY</span></button>
    </div>
    <div class="additional-text" ref="additionalText" @click="copyText">
      <span>CA:</span><span>t5kcK2JaTN2J89N3LT1MXK2Ye7LRPdR3nyC6qdmpump</span>
    </div>
    <div class="social-icons">
      <div class="icon icon-twitter" @mouseover="hoverTwitter = true" @mouseleave="hoverTwitter = false" @click="redirectToTwitter">
        <span class="icon-bg" :class="{ 'hovered': hoverTwitter }"></span>
        <img v-if="!hoverTwitter" src="icon_tw@2x.png" class="icon-img" alt="Twitter">
        <img v-else src="icon_tw2@2x.png" class="icon-img" alt="Twitter">
      </div>
      <div class="icon icon-telegram" @mouseover="hoverTelegram = true" @mouseleave="hoverTelegram = false" @click="redirectToTelegram">
        <span class="icon-bg" :class="{ 'hovered': hoverTelegram }"></span>
        <img v-if="!hoverTelegram" src="icon_telg@2x.png" class="icon-img" alt="Telegram">
        <img v-else src="icon_telg2@2x.png" class="icon-img" alt="Telegram">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverTwitter: false,
      hoverTelegram: false
    };
  },
  mounted() {
    console.log(this.$refs.contractAddress); // 确认是否正确绑定
  },
  methods: {
    copyText() {
      const additionalTextElement = this.$refs.additionalText;
      if (additionalTextElement) {
        const contractAddressText = additionalTextElement.querySelector('span:last-child').innerText;
        navigator.clipboard.writeText(contractAddressText).then(() => {
          alert('Copied to clipboard');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      }
    },
    redirectToTwitter() {
      window.location.href = 'https://x.com/phrygesol';
    },
    redirectToTelegram() {
      window.location.href = 'https://t.me/Phryge_sol';
    }
  }
};
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'Paris2024-Variable';
  src: url('@/assets/fonts/Paris2024-Variable.ttf') format('truetype');
}

/* 全局设置字体 */
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Paris2024-Variable';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-image {
  position: absolute;
  top: 32%;
  left: 52%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.5s ease, filter 0.5s ease; /* 延长过渡时间 */
  animation: float 3s ease-in-out infinite; /* 添加浮动效果 */
}

.title-image img {
  width: 86%; /* 缩小图片 */
  height: auto;
}

.title-image:hover {
  transform: translate(-50%, -50%) scale(1.05) rotate(2deg); /* 调整旋转角度和放大比例 */
  filter: brightness(1.1); /* 调整亮度 */
  animation: shake 0.5s ease-in-out infinite; /* 使用 ease-in-out 的动画效果 */
}

@keyframes shake {
  0%, 100% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-50%, -50%) rotate(3deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-2deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(1deg);
  }
}


.content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(0, 0, 0);
}

.additional-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 22.5vh; /* 向上移动位置 */
  font-size: min(5vw, 28px);
  color: #000000;
  cursor: pointer;
  letter-spacing: 0.1vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500; /* 设置按钮文字为粗体 */
}

.additional-text:hover span:last-child {
  text-decoration: underline;
}

.text {
  font-size: 2em;
  max-width: 680px;
  overflow-wrap: break-word;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.6;
}

.button {
  margin-top: 28px;
  margin-left: -5%;
  padding: 10px 55px;
  font-size: 3.5em;
  color: #ffffff;
  font-family: 'Paris2024-Variable';
  font-weight: 500; /* 设置按钮文字为粗体 */
  background-color: #CE4446;
  border: 3px solid #000000d3;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 2;
  display: flex;
  position: relative;
  box-shadow: 0px 7px 0px #2B61CD; /* 添加正下方的投影 */
}

.buy-text {
  position: relative;
  top: -2px; /* 向上移动按钮文字 */
}

.button:hover .buy-text {
  color: #ffffff;
}

.button:hover {
  background-color: #b13436;
  border-color: rgb(0, 0, 0);
  box-shadow: none; /* 鼠标悬浮时投影消失 */
}

.social-icons {
  position: absolute;
  bottom: 10vh; /* 向下移动图标 */
  left: 50.1%; /* 水平居中 */
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 3;
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  margin: 0 20px; /* 调整图标之间的间距 */
  cursor: pointer;
  transition: transform 0.3s; /* 添加过渡效果 */
}

.icon:hover {
  transform: translateY(-10px); /* 鼠标悬浮时向上移动 */
}

.icon-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background-color: #FFFFFF; /* 默认背景颜色为白色 */
  border: 2px solid black; /* 添加黑色描边 */
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: -1;
}

.icon:hover .icon-bg {
  background-color: #CE4446; /* 悬停时背景颜色 */
}

.social-icons img {
  width: 26px;
  height: 26px;
}

.icon-img {
  position: relative;
  z-index: 1;
}
</style>
